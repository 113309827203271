"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-sidebar kiwi-theme-bg",
    class: ['kiwi-sidebar-section-' + _vm.section]
  }, [!_vm.sidebarState.isOpen ? _c('span', {
    staticClass: "kiwi-sidebar-options"
  }, [_c('div', {
    staticClass: "kiwi-sidebar-close",
    on: {
      "click": function click($event) {
        return _vm.sidebarState.close();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close'))), _c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    }
  })])]) : _vm._e(), _vm.sidebarState.activeComponent ? [_c(_vm.sidebarState.activeComponent, _vm._b({
    tag: "component",
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }, 'component', _vm.sidebarState.activeComponentProps, false))] : _vm.buffer ? [_vm.buffer.isChannel() ? [_vm.section === 'settings' ? _c('sidebar-section-settings', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }) : _vm.section === 'user' ? _c('div', {
    staticClass: "kiwi-sidebar-userbox",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('user-box', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "user": _vm.sidebarState.sidebarUser,
      "sidebar-state": _vm.sidebarState
    }
  })], 1) : _vm.section === 'nicklist' ? _c('nicklist', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }) : _vm.section === 'about' ? _c('sidebar-about-buffer', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "sidebar-state": _vm.sidebarState
    }
  }) : _vm._e()] : _vm.buffer.isQuery() ? [_vm.section === 'user' ? _c('div', {
    staticClass: "kiwi-sidebar-userbox",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('user-box', {
    attrs: {
      "network": _vm.network,
      "buffer": _vm.buffer,
      "user": _vm.sidebarState.sidebarUser,
      "sidebar-state": _vm.sidebarState
    }
  })], 1) : _vm._e()] : _vm._e()] : [_vm._v(" " + _vm._s(_vm.$t('side_buffer')) + " ")]], 2);
};
var staticRenderFns = exports.staticRenderFns = [];