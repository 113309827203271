"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
var _UserAvatar = _interopRequireDefault(require("./UserAvatar"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default,
    UserAvatar: _UserAvatar.default
  },
  props: ['network'],
  data: function data() {
    return {
      is_usermenu_open: false
    };
  },
  computed: {
    networkName: function networkName() {
      var name = TextFormatting.t('no_network');
      if (this.network) {
        name = this.network.name;
      }
      return name;
    },
    getUser: function getUser() {
      if (this.network && this.network.state && this.network.currentUser()) {
        return this.network.currentUser();
      }
      return null;
    },
    isConnected: function isConnected() {
      return this.network && this.network.state === 'connected';
    }
  },
  methods: {
    clickForget: function clickForget() {
      var msg = 'This will delete all stored networks and start fresh. Are you sure?';
      /* eslint-disable no-restricted-globals, no-alert */
      var confirmed = confirm(msg);
      if (!confirmed) {
        return;
      }
      this.$state.persistence.forgetState();
      window.location.reload();
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["StateBrowserUsermenu"]
window._kiwi_exports.components.StateBrowserUsermenu = exports.default ? exports.default : exports;
