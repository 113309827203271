"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "kiwi-personal"
  }, [_c('h1', [_vm._v(_vm._s(_vm.$t('personal_client')))]), _c('p', [_vm._v(_vm._s(_vm.$t('personal_addjoin')))]), _c('p', [_vm._v(_vm._s(_vm.$t('personal_return')))]), _c('button', {
    staticClass: "u-button u-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.addNetwork
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_add')) + " ")]), _vm._v(" "), _c('br'), _vm.networks.length > 0 ? _c('a', {
    staticClass: "u-link kiwi-personal-existing-networks",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleStateBrowser.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('personal_saved')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "kiwi-aboutnew-content",
    class: {
      'kiwi-aboutnew-content--open': _vm.about_open
    },
    domProps: {
      "innerHTML": _vm._s(_vm.aboutContent)
    }
  }), _c('div', {
    staticClass: "kiwi-aboutnew"
  }, [_c('div', {
    staticClass: "kiwi-aboutnew-sep"
  }), _c('a', {
    staticClass: "u-link",
    on: {
      "click": function click($event) {
        _vm.about_open = !_vm.about_open;
      }
    }
  }, [_vm._v(" Link to your network / channel ")]), _c('div', {
    staticClass: "kiwi-aboutnew-help"
  }, [_c('a', {
    on: {
      "click": function click($event) {
        _vm.about_open = !_vm.about_open;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-question-circle",
    attrs: {
      "aria-hidden": "true"
    }
  })])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];