"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = exports.render = function render() {
  var _vm$startupOptions$en;
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-networksettings"
  }, [_c('form', {
    staticClass: "u-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.connect.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "kiwi-title"
  }, [_vm._v(_vm._s(_vm.$t('settings_server_details')))]), _c('div', {
    staticClass: "kiwi-networksettings-section-block"
  }, [_vm.network.state_error ? _c('div', {
    staticClass: "kiwi-networksettings-error"
  }, [_vm._v(" " + _vm._s(_vm.$t('network_noconnect')) + " "), _c('span', [_vm._v(_vm._s(_vm.readableStateError(_vm.network.state_error)))])]) : _vm.network.last_error ? _c('div', {
    staticClass: "kiwi-networksettings-error"
  }, [_c('span', [_vm._v(_vm._s(_vm.network.last_error))])]) : _vm._e(), _c('captcha', {
    staticClass: "kiwi-networksettings-captcha",
    attrs: {
      "network": _vm.network
    }
  }), _vm.network.editable_name ? _c('div', {
    staticClass: "kiwi-networksettings-networkname"
  }, [_c('input-text', {
    attrs: {
      "label": _vm.$t('network_name')
    },
    model: {
      value: _vm.network.name,
      callback: function callback($$v) {
        _vm.$set(_vm.network, "name", $$v);
      },
      expression: "network.name"
    }
  })], 1) : _vm._e(), _c('server-selector', {
    attrs: {
      "enable-custom": (_vm$startupOptions$en = _vm.startupOptions.enableCustom) !== null && _vm$startupOptions$en !== void 0 ? _vm$startupOptions$en : true,
      "disabled": _vm.network.state !== 'disconnected',
      "connection": _vm.network.connection
    }
  }), _c('div', {
    staticClass: "kiwi-networksettings-connection-password"
  }, [_vm.server_type === 'network' ? [_c('input-text', {
    attrs: {
      "label": _vm.$t('settings_nickname')
    },
    model: {
      value: _vm.network.connection.nick,
      callback: function callback($$v) {
        _vm.$set(_vm.network.connection, "nick", $$v);
      },
      expression: "network.connection.nick"
    }
  }), _c('input-text', {
    attrs: {
      "show-plain-text": true,
      "label": _vm.$t('password'),
      "type": "password"
    },
    model: {
      value: _vm.network.password,
      callback: function callback($$v) {
        _vm.$set(_vm.network, "password", $$v);
      },
      expression: "network.password"
    }
  })] : [_c('input-text', {
    attrs: {
      "label": _vm.$t('username')
    },
    model: {
      value: _vm.znc_username,
      callback: function callback($$v) {
        _vm.znc_username = $$v;
      },
      expression: "znc_username"
    }
  }), _c('input-text', {
    attrs: {
      "label": _vm.$t('network')
    },
    model: {
      value: _vm.znc_network,
      callback: function callback($$v) {
        _vm.znc_network = $$v;
      },
      expression: "znc_network"
    }
  }), _c('input-text', {
    attrs: {
      "label": _vm.$t('password'),
      "type": "password"
    },
    model: {
      value: _vm.znc_password,
      callback: function callback($$v) {
        _vm.znc_password = $$v;
      },
      expression: "znc_password"
    }
  })]], 2), _c('div', {
    staticClass: "kiwi-networksettings-server-types"
  }, [_vm.server_type === 'znc' ? _c('div', {
    staticClass: "kiwi-networksettings-server-types-info"
  }, [_vm._v(" " + _vm._s(_vm.$t('settings_znc_other')) + " ")]) : _vm._e(), _c('a', {
    staticClass: "u-link kiwi-network-type-button",
    class: {
      'kiwi-networksettings-server-type-active': _vm.server_type === 'network'
    },
    on: {
      "click": function click($event) {
        _vm.server_type = 'network';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('network')) + " ")]), _c('a', {
    staticClass: "u-link kiwi-network-type-button",
    class: {
      'kiwi-networksettings-server-type-active': _vm.server_type === 'znc'
    },
    on: {
      "click": function click($event) {
        _vm.server_type = 'znc';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('znc')) + " ")])]), _c('h4', {
    staticClass: "kiwi-show-advanced-title",
    on: {
      "click": function click($event) {
        _vm.show_advanced = !_vm.show_advanced;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('settings_advanced')) + " "), _c('i', {
    staticClass: "fa",
    class: ['fa-caret-' + (_vm.show_advanced ? 'up' : 'down')],
    attrs: {
      "aria-hidden": "true"
    }
  })]), _vm.show_advanced ? _c('div', {
    staticClass: "kiwi-networksettings-advanced"
  }, [_c('input-text', {
    attrs: {
      "label": _vm.$t('settings_encoding')
    },
    model: {
      value: _vm.network.connection.encoding,
      callback: function callback($$v) {
        _vm.$set(_vm.network.connection, "encoding", $$v);
      },
      expression: "network.connection.encoding"
    }
  }), _c('input-text', {
    attrs: {
      "show-plain-text": true,
      "label": _vm.$t('server_password'),
      "type": "password"
    },
    model: {
      value: _vm.network.connection.password,
      callback: function callback($$v) {
        _vm.$set(_vm.network.connection, "password", $$v);
      },
      expression: "network.connection.password"
    }
  }), _c('input-text', {
    attrs: {
      "label": _vm.$t('whois_realname')
    },
    model: {
      value: _vm.network.gecos,
      callback: function callback($$v) {
        _vm.$set(_vm.network, "gecos", $$v);
      },
      expression: "network.gecos"
    }
  }), _c('label', [_c('span', {
    staticClass: "kiwi-appsettings-showraw-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('settings_show_raw')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingShowRaw,
      expression: "settingShowRaw"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingShowRaw) ? _vm._i(_vm.settingShowRaw, null) > -1 : _vm.settingShowRaw
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingShowRaw,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingShowRaw = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.settingShowRaw = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingShowRaw = $$c;
        }
      }
    }
  })]), _c('label', [_c('span', {
    staticClass: "kiwi-appsettings-showraw-label"
  }, [_vm._v(" " + _vm._s(_vm.$t('settings_use_websocket')) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.network.connection.direct,
      expression: "network.connection.direct"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.network.connection.direct) ? _vm._i(_vm.network.connection.direct, null) > -1 : _vm.network.connection.direct
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.network.connection.direct,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.network.connection, "direct", (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context2;
            $$i > -1 && _vm.$set(_vm.network.connection, "direct", (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.$set(_vm.network.connection, "direct", $$c);
        }
      }
    }
  }), _vm.network.connection.direct ? _c('input-text', {
    model: {
      value: _vm.directWs,
      callback: function callback($$v) {
        _vm.directWs = $$v;
      },
      expression: "directWs"
    }
  }) : _vm._e()], 1), _c('label', {
    staticClass: "u-form-block"
  }, [_c('input-text', {
    attrs: {
      "label": _vm.$t('settings_autorun'),
      "type": "textarea"
    },
    model: {
      value: _vm.network.auto_commands,
      callback: function callback($$v) {
        _vm.$set(_vm.network, "auto_commands", $$v);
      },
      expression: "network.auto_commands"
    }
  })], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "kiwi-networksettings-control"
  }, [_vm.network.state !== 'connected' ? _c('a', {
    staticClass: "u-button u-button-primary u-submit kiwi-connect-to-newnetwork",
    attrs: {
      "type": "button",
      "disabled": _vm.network.state !== 'disconnected'
    },
    on: {
      "click": function click($event) {
        return _vm.connect();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.network.state === 'disconnected' ? 'network_connect' : 'network_connecting')) + " ")]) : _c('a', {
    staticClass: "u-button u-button-warning u-submit kiwi-connect-to-newnetwork",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.disconnect();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('network_disconnect')) + " ")])])], 1), _c('div', {
    staticClass: "kiwi-dangerzone"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_danger')))]), _c('a', {
    staticClass: "u-button u-button-warning",
    on: {
      "click": _vm.removeNetwork
    }
  }, [_c('i', {
    staticClass: "fa fa-times",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('settings_remove')) + " ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];