"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = require("vue");
var _state = _interopRequireDefault(require("@/libs/state"));
var _UserAvatarCommon = require("./UserAvatarCommon");
var _default = exports.default = {
  __name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      default: null
    },
    message: {
      type: Object,
      default: null
    },
    network: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'small'
    },
    forceShowStatus: {
      type: Boolean,
      default: false
    },
    allowToggle: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var state = (0, _state.default)();
    var getSizeObj = function getSizeObj(size) {
      return {
        url: props.user.avatar[size],
        vbind: {
          'href': props.user.avatar[size],
          'xlink:href': props.user.avatar[size]
        },
        setFailed: function setFailed() {
          var avatar = props.user.avatar;
          var failedAvatar = avatar[size];
          avatar[size] = '';
          if (!avatar.small && !avatar.large) {
            state.$emit('user.avatar.failed', {
              user: props.user,
              network: props.network,
              failed: failedAvatar
            });
          }
        }
      };
    };
    var shouldShowStatus = function shouldShowStatus() {
      if (!props.network || !props.user) {
        return false;
      }
      if (props.forceShowStatus) {
        return true;
      }
      if (props.network.state !== 'connected' || !state.setting('showAwayStatusIndicators')) {
        return false;
      }
      var awayNotifyEnabled = props.network.ircClient.network.cap.isEnabled('away-notify');
      return state.setting('buffers.who_loop') || awayNotifyEnabled;
    };
    var awayStatus = (0, _vue.computed)(function () {
      var show = shouldShowStatus();
      var vbind = {};
      if (show) {
        vbind.mask = 'url(#kiwi-avatar-mask)';
      }
      var transform = (0, _UserAvatarCommon.getAvatarTransform)();
      return {
        show: show,
        vbind: vbind,
        transform: transform
      };
    });
    var avatar = (0, _vue.computed)(function () {
      var _props$message, _props$user;
      var initialsLength = state.setting('avatars.initials_length');
      if (!initialsLength || initialsLength < 1) {
        initialsLength = 1;
      }
      if (initialsLength > 2) {
        initialsLength = 2;
      }
      var nick = ((_props$message = props.message) === null || _props$message === void 0 ? void 0 : _props$message.nick) || ((_props$user = props.user) === null || _props$user === void 0 ? void 0 : _props$user.nick) || 'User';
      var initials = nick.substring(0, initialsLength).toUpperCase();
      var hasImage = !!(props.user && (props.user.avatar.small || props.user.avatar.large));
      var showBackground = !hasImage || state.setting('avatars.show_image_background');
      var backgroundStyle = {};
      var avatars = {
        hasImage: hasImage,
        initials: initials,
        showBackground: showBackground,
        backgroundStyle: backgroundStyle,
        sizeKey: {}
      };
      if (props.user) {
        backgroundStyle.fill = props.user.getColour();
      } else {
        return avatars;
      }
      if (props.user.avatar.small) {
        avatars.small = getSizeObj('small');
        avatars.sizeKey.small = 'small';
        if (!props.user.avatar.large) {
          avatars.sizeKey.large = 'small';
        }
      }
      if (props.user.avatar.large) {
        avatars.large = getSizeObj('large');
        avatars.sizeKey.large = 'large';
        if (!props.user.avatar.small) {
          avatars.sizeKey.small = 'large';
        }
      }
      return avatars;
    });
    var toggleAway = function toggleAway() {
      if (!props.allowToggle || props.user.away === 'offline') {
        return;
      }
      var isAway = props.user.isAway();
      props.network.ircClient.raw('AWAY', isAway ? '' : 'Currently away');
    };
    return {
      __sfc: true,
      state: state,
      props: props,
      getSizeObj: getSizeObj,
      shouldShowStatus: shouldShowStatus,
      awayStatus: awayStatus,
      avatar: avatar,
      toggleAway: toggleAway
    };
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UserAvatar"]
window._kiwi_exports.components.UserAvatar = exports.default ? exports.default : exports;
