"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  components: {
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: {
    network: Object
  },
  data: function data() {
    return {
      new_nick: '',
      error_message: '',
      event_listeners: [],
      self_user_settings_open: false
    };
  },
  computed: {
    user: function user() {
      return this.network.currentUser();
    },
    modeString: function modeString() {
      var user = this.user;
      if (!user.modes.length) {
        return '';
      }
      return user.modes.reduce(function (acc, item) {
        return acc + item.mode;
      }, '+');
    },
    awayStatus: {
      get: function get() {
        return this.user.away;
      },
      set: function set(val) {
        this.network.ircClient.raw('AWAY', val ? 'Currently away' : '');
      }
    }
  },
  methods: {
    openSelfActions: function openSelfActions() {
      this.self_user_settings_open = true;
      this.error_message = '';
    },
    openProfile: function openProfile() {
      this.$state.$emit('userbox.show', this.user);
    },
    closeSelfUser: function closeSelfUser() {
      this.$emit('close');
    },
    onNewNickSubmit: function onNewNickSubmit(newVal, done) {
      if (this.event_listeners.length) {
        // nick change already in progress
        return;
      }
      this.new_nick = newVal;
      this.changeNick(done);
    },
    changeNick: function changeNick(done) {
      var _context;
      var nick = (0, _trim.default)(_context = this.new_nick).call(_context);
      if (nick.length === 0) {
        this.error_message = TextFormatting.t('error_empty_nick');
        done();
        return;
      }
      if (nick.match(/(^[0-9])|(\s)/)) {
        this.error_message = TextFormatting.t('error_no_number');
        done();
        return;
      }
      if (nick === this.user.nick) {
        this.error_message = TextFormatting.t('error_nick_in_use', {
          nick: nick
        });
        done();
        return;
      }
      this.error_message = '';
      this.listenForNickEvents(done);
      this.network.ircClient.changeNick(nick);
    },
    listenForNickEvents: function listenForNickEvents(done) {
      var _this = this;
      this.event_listeners.push(this.listen(this.network.ircClient, 'nick', function (event) {
        if (event.new_nick !== _this.user.nick) {
          return;
        }
        _this.closeNickChange();
      }));
      this.event_listeners.push(this.listen(this.network.ircClient, 'nick in use', function (event) {
        _this.error_message = TextFormatting.t('error_nick_in_use', {
          nick: event.nick
        });
        _this.removeNickEventListeners();
        done();
      }));
      this.event_listeners.push(this.listen(this.network.ircClient, 'nick invalid', function (event) {
        _this.error_message = TextFormatting.t('error_nick_invalid', {
          nick: event.nick
        });
        _this.removeNickEventListeners();
        done();
      }));

      // Maybe the nickchange will result in an event we are not listening for above
      var timeout = this.setTimeout(function () {
        _this.error_message = TextFormatting.t('error_unexpected');
        _this.removeNickEventListeners();
      }, 4000);
      this.event_listeners.push(function () {
        _this.clearTimeout(timeout);
      });
    },
    removeNickEventListeners: function removeNickEventListeners() {
      while (this.event_listeners.length) {
        this.event_listeners.shift()();
      }
    },
    closeNickChange: function closeNickChange() {
      this.removeNickEventListeners();
      this.self_user_settings_open = false;
    },
    networkSupportsAway: function networkSupportsAway() {
      return this.network.ircClient.network.cap.isEnabled('away-notify');
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SelfUser"]
window._kiwi_exports.components.SelfUser = exports.default ? exports.default : exports;
