"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    pluginElement: Element,
    pluginProps: Object
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["PluginWrapper"]
window._kiwi_exports.components.utils.PluginWrapper = exports.default ? exports.default : exports;
