"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));
var render = exports.render = function render(_c, _vm) {
  return _vm.$options.m.shouldShowStatus(_vm.props) ? _c('span', {
    staticClass: "kiwi-awaystatusindicator",
    class: (0, _defineProperty2.default)({
      'kiwi-awaystatusindicator--offline': !_vm.props.user || _vm.props.user.isOffline(),
      'kiwi-awaystatusindicator--away': _vm.props.user && _vm.props.user.isAway(),
      'kiwi-awaystatusindicator--self': _vm.$options.m.isUserSelf(_vm.props)
    }, _vm.data.staticClass, !!_vm.data.staticClass),
    on: {
      "click": function click($event) {
        return _vm.$options.m.toggleSelfAway(_vm.props);
      }
    }
  }) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];