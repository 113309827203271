"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _lodash = _interopRequireDefault(require("lodash"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var _CommonLayout = _interopRequireDefault(require("./CommonLayout"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  components: {
    StartupLayout: _CommonLayout.default
  },
  data: function data() {
    return {
      connectErrors: [],
      network: null,
      network_extras: null,
      username: '',
      password: '',
      znc_network: '',
      showNetwork: true,
      showPass: true,
      showUser: true,
      show_password_box: false
    };
  },
  computed: {
    greetingText: function greetingText() {
      var greeting = this.$state.settings.startupOptions.greetingText;
      return typeof greeting === 'string' ? greeting : this.$t('start_greeting');
    },
    buttonText: function buttonText() {
      var greeting = this.$state.settings.startupOptions.buttonText;
      return typeof greeting === 'string' ? greeting : this.$t('start_button');
    },
    readyToStart: function readyToStart() {
      return this.username && (this.password || this.showPass === false);
    },
    infoContent: function infoContent() {
      return this.$state.settings.startupOptions.infoContent || '';
    }
  },
  created: function created() {
    var options = this.$state.settings.startupOptions;
    this.username = options.username || '';
    this.password = options.password || '';
    this.znc_network = window.location.hash.substr(1) || options.network || '';
    this.showNetwork = typeof options.showNetwork === 'boolean' ? options.showNetwork : true;
    this.showUser = typeof options.showUser === 'boolean' ? options.showUser : true;
    this.showPass = typeof options.showPass === 'boolean' ? options.showPass : true;
    if (options.autoConnect && this.username && this.password) {
      this.startUp();
    }
    window.test = this;
  },
  methods: {
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    formSubmit: function formSubmit() {
      if (this.readyToStart) {
        this.startUp();
      }
    },
    addNetwork: function addNetwork(netName) {
      var options = this.$state.settings.startupOptions;
      var password = this.username;
      if (netName) {
        password += '/' + netName;
      }
      password += ':' + this.password;
      var net = this.$state.addNetwork(netName, 'ZNC', {
        server: (0, _trim.default)(_lodash.default).call(_lodash.default, options.server),
        port: options.port,
        tls: options.tls,
        password: password
      });
      return net;
    },
    startUp: function startUp() {
      var _this = this;
      this.connectErrors = [];
      if (this.network) {
        this.$state.removeNetwork(this.network.id);
      }
      var netList = _lodash.default.compact(this.znc_network.split(','));
      if (netList.length === 0) {
        netList.push('');
      }

      // add our first network and make sure we can connect
      // before trying to add other networks.
      var net = this.network = this.addNetwork(netList.shift());
      this.network_extras = netList;
      var onRegistered = function onRegistered() {
        _this.$state.setActiveBuffer(net.id, net.serverBuffer().name);
        if (_this.$refs.layout) {
          _this.$refs.layout.close();
        }
        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
        net.ircClient.off('irc error', onError);
        _this.network_extras.forEach(function (netName, idx) {
          var extraNet = _this.addNetwork((0, _trim.default)(_lodash.default).call(_lodash.default, netName));
          extraNet.ircClient.connect();
        });
      };
      var onClosed = function onClosed() {
        var _context;
        var lastError = _this.network.last_error;
        if (lastError && !(0, _includes.default)(_context = _this.connectErrors).call(_context, lastError)) {
          _this.connectErrors.push(lastError);
        }
        if (!_this.connectErrors.length && !net.state_error) {
          _this.connectErrors.push(_this.$t('error_closed_unexpected'));
        }
        net.ircClient.off('registered', onRegistered);
        net.ircClient.off('close', onClosed);
        net.ircClient.off('irc error', onError);
      };
      var onError = function onError(event) {
        var _context2;
        if (event.reason && !(0, _includes.default)(_context2 = _this.connectErrors).call(_context2, event.reason)) {
          _this.connectErrors.push(event.reason);
        }
      };
      net.ircClient.once('registered', onRegistered);
      net.ircClient.once('close', onClosed);
      net.ircClient.on('irc error', onError);
      net.ircClient.connect();
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["startups"]) window._kiwi_exports["components"]["startups"] = {};
window._kiwi_exports["components"]["startups"]["ZncLogin"]
window._kiwi_exports.components.startups.ZncLogin = exports.default ? exports.default : exports;
