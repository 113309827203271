"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var IrcdDiffs = _interopRequireWildcard(require("@/helpers/IrcdDiffs"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var basicBanListSorter = function basicBanListSorter(a, b) {
  if (a.banned_at === b.banned_at) {
    return Misc.strCompare(a.banned, b.banned);
  }
  return b.banned_at - a.banned_at;
};
var getBanListSorter = function getBanListSorter(extban) {
  if (!extban) {
    return basicBanListSorter;
  }
  var extbanColon = extban + ':';
  return function (a, b) {
    var aAccount = a.banned.indexOf(extbanColon) === 0;
    var bAccount = b.banned.indexOf(extbanColon) === 0;
    if (aAccount && !bAccount) {
      return -1;
    }
    if (!aAccount && bAccount) {
      return 1;
    }
    return basicBanListSorter(a, b);
  };
};
var _default = exports.default = {
  props: ['buffer'],
  data: function data() {
    return {
      banMask: '',
      banList: [],
      is_refreshing: false,
      clickUpdateTimeout: 0
    };
  },
  computed: {
    extban: function extban() {
      return IrcdDiffs.extbanAccount(this.buffer.getNetwork());
    },
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    },
    sortedBanList: function sortedBanList() {
      var _context, _context2;
      var sorter = getBanListSorter(this.extban);
      return (0, _sort.default)(_context = (0, _slice.default)(_context2 = this.banList).call(_context2)).call(_context, sorter);
    }
  },
  watch: {
    buffer: function buffer() {
      this.banList = [];
      this.is_refreshing = false;
      this.clickUpdateTimeout = 0;
      this.updateBanlist();
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'irc.mode', function (event, network) {
      if (network !== _this.buffer.getNetwork()) {
        return;
      }
      if (!network.ircClient.caseCompare(event.target, _this.buffer.name)) {
        return;
      }
      event.modes.forEach(function (change) {
        if (change.mode.length !== 2 || change.mode[1] !== 'b') {
          return;
        }
        if (change.mode[0] === '+') {
          _this.banList.push({
            banned: change.param,
            banned_at: Math.floor(event.time / 1000),
            banned_by: event.nick,
            channel: event.target
          });
        } else if (change.mode[0] === '-') {
          var _context3;
          _this.banList = (0, _filter.default)(_context3 = _this.banList).call(_context3, function (ban) {
            return ban.banned !== change.param;
          });
        }
      });
    });
    this.updateBanlist();
  },
  methods: {
    displayMask: function displayMask(banMask) {
      return banMask.replace(this.extban + ':', '');
    },
    clickUpdateBanlist: function clickUpdateBanlist() {
      var _this2 = this;
      if (this.clickUpdateTimeout) {
        return;
      }
      this.clickUpdateTimeout = setTimeout(function () {
        _this2.clickUpdateTimeout = 0;
      }, 4000);
      this.updateBanlist();
    },
    updateBanlist: function updateBanlist() {
      var _this3 = this;
      if (this.is_refreshing || this.buffer.getNetwork().state !== 'connected') {
        return;
      }
      var channelName = this.buffer.name;
      var network = this.buffer.getNetwork();
      this.is_refreshing = true;
      network.ircClient.banlist(channelName, function (event) {
        var currentNetwork = _this3.buffer.getNetwork();
        var bufferMatches = currentNetwork.ircClient.caseCompare(_this3.buffer.name, event.channel);
        if (!bufferMatches || network !== currentNetwork) {
          // The buffer or network changed since making the request
          return;
        }
        _this3.banList = event.bans;
        _this3.is_refreshing = false;
      });
    },
    addBan: function addBan() {
      var _context4;
      var mask = (0, _trim.default)(_context4 = this.banMask).call(_context4);
      if (!mask) {
        return;
      }
      var ircClient = this.buffer.getNetwork().ircClient;
      ircClient.ban(this.buffer.name, mask);
      this.banMask = '';
    },
    removeBan: function removeBan(mask) {
      var channelName = this.buffer.name;
      this.buffer.getNetwork().ircClient.unban(channelName, mask);
    },
    banKeyDown: function banKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.addBan();
      }
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ChannelBanlist"]
window._kiwi_exports.components.ChannelBanlist = exports.default ? exports.default : exports;
