"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Vue = require('vue');
var _default = exports.default = Vue.component('input-prompt', {
  props: ['label', 'hideCancel', 'block'],
  data: function data() {
    return {
      value: '',
      state: 'pre',
      waiting: false
    };
  },
  computed: {
    hasChildren: function hasChildren() {
      return !!this.$slots.default;
    }
  },
  created: function created() {
    if (!this.hasChildren) {
      this.prompt();
    }
  },
  methods: {
    prompt: function prompt() {
      var _this = this;
      this.state = 'prompt';
      this.$nextTick(function () {
        _this.$el.querySelector('input').focus();
      });
    },
    complete: function complete() {
      var _this2 = this;
      if (this.waiting) {
        return;
      }
      if (this.hasChildren) {
        this.state = 'pre';
      } else {
        this.waiting = true;
      }
      var doneWaiting = function doneWaiting() {
        _this2.waiting = false;
      };
      this.$emit('submit', this.value, doneWaiting);
    },
    cancel: function cancel() {
      if (this.hasChildren) {
        this.state = 'pre';
      }
      this.$emit('cancel');
    }
  }
});
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["InputPrompt"]
window._kiwi_exports.components.utils.InputPrompt = exports.default ? exports.default : exports;
