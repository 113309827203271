"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('startup-layout', {
    ref: "layout",
    staticClass: "kiwi-welcome-znc",
    scopedSlots: _vm._u([!_vm.network || _vm.network.state === 'disconnected' ? {
      key: "connection",
      fn: function fn() {
        return [_c('form', {
          staticClass: "u-form u-form--big kiwi-welcome-znc-form",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.formSubmit.apply(null, arguments);
            }
          }
        }, [_c('h2', {
          domProps: {
            "innerHTML": _vm._s(_vm.greetingText)
          }
        }), _vm.network && (_vm.connectErrors.length > 0 || _vm.network.state_error) ? _c('div', {
          staticClass: "kiwi-welcome-znc-error"
        }, [_vm.connectErrors.length > 0 ? _vm._l(_vm.connectErrors, function (err) {
          return _c('span', {
            key: err
          }, [_vm._v(_vm._s(err))]);
        }) : [_c('span', [_vm._v(_vm._s(_vm.$t('network_noconnect')))]), _c('span', [_vm._v(_vm._s(_vm.readableStateError(_vm.network.state_error)))])]], 2) : _vm._e(), _vm.showUser ? _c('input-text', {
          staticClass: "kiwi-welcome-znc-nick",
          attrs: {
            "label": _vm.$t('username')
          },
          model: {
            value: _vm.username,
            callback: function callback($$v) {
              _vm.username = $$v;
            },
            expression: "username"
          }
        }) : _vm._e(), _vm.showPass ? _c('input-text', {
          staticClass: "kiwi-welcome-znc-password",
          attrs: {
            "show-plain-text": true,
            "label": _vm.$t('password'),
            "type": "password"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }) : _vm._e(), _vm.showNetwork ? _c('input-text', {
          staticClass: "kiwi-welcome-znc-channel",
          attrs: {
            "label": _vm.$t('network')
          },
          model: {
            value: _vm.znc_network,
            callback: function callback($$v) {
              _vm.znc_network = $$v;
            },
            expression: "znc_network"
          }
        }) : _vm._e(), _c('button', {
          staticClass: "u-button u-button-primary u-submit kiwi-welcome-znc-start",
          attrs: {
            "disabled": !_vm.readyToStart,
            "type": "submit"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.buttonText)
          }
        })], 1)];
      },
      proxy: true
    } : _vm.network.state !== 'connected' ? {
      key: "connection",
      fn: function fn() {
        return [_c('i', {
          staticClass: "fa fa-spin fa-spinner",
          staticStyle: {
            "font-size": "2em",
            "margin-top": "1em"
          }
        })];
      },
      proxy: true
    } : null], null, true)
  });
};
var staticRenderFns = exports.staticRenderFns = [];