"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _lodash = _interopRequireDefault(require("lodash"));
var settingTools = _interopRequireWildcard(require("@/libs/settingTools"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  data: function data() {
    return {
      filterString: ''
    };
  },
  computed: {
    filteredSettings: function filteredSettings() {
      var settings = this.settings;
      var filter = this.filterString.toLowerCase();
      var out = [];
      (0, _keys.default)(settings).forEach(function (key) {
        var value = settings[key];
        if (value.key.toLowerCase().indexOf(filter) !== -1) {
          out.push(value);
        }
      });
      return out;
    },
    settings: function settings() {
      var _context;
      var out = {};
      var base = [];
      settingTools.buildTree(out, base, this.$state.getSetting('settings'), false);
      settingTools.buildTree(out, base, this.$state.getSetting('user_settings'), true);
      return _lodash.default.orderBy((0, _map.default)(_context = (0, _keys.default)(out)).call(_context, function (key) {
        return out[key];
      }), [function (o) {
        return o.key.split('.').length - 1;
      }, 'key'], ['asc']);
    }
  },
  methods: {
    resetValue: function resetValue(event, settingKey) {
      var newVal = this.$state.getSetting('settings.' + settingKey);
      if (!newVal) {
        newVal = null;
      }
      this.$state.setting(settingKey, newVal);
    },
    updateSetting: function updateSetting(event, settingKey) {
      var target = event.target;
      var val = target.type === 'checkbox' ? target.checked : target.value;
      switch (target.type) {
        case 'checkbox':
          val = target.checked;
          break;
        case 'number':
          val = parseInt(target.value, 10);
          break;
        default:
          val = target.value;
          break;
      }
      if (this.$state.setting(settingKey) === val) {
        return;
      }
      this.$state.setting(settingKey, val);
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SettingsAdvanced"]
window._kiwi_exports.components.SettingsAdvanced = exports.default ? exports.default : exports;
