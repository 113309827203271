"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "kiwi-ircinput"
  }, [_c('div', {
    ref: "editor",
    staticClass: "kiwi-ircinput-editor",
    attrs: {
      "placeholder": _vm.placeholder,
      "contenteditable": "true",
      "role": "textbox",
      "spellcheck": "true"
    },
    on: {
      "keypress": function keypress($event) {
        _vm.updateValueProps();
        _vm.$emit('keypress', $event);
      },
      "keydown": function keydown($event) {
        _vm.updateValueProps();
        _vm.$emit('keydown', $event);
      },
      "keyup": function keyup($event) {
        _vm.updateValueProps();
        _vm.$emit('keyup', $event);
      },
      "textInput": function textInput($event) {
        _vm.updateValueProps();
        _vm.onTextInput($event);
        _vm.$emit('textInput', $event);
      },
      "mouseup": function mouseup($event) {
        return _vm.updateValueProps();
      },
      "click": function click($event) {
        return _vm.$emit('click', $event);
      },
      "paste": _vm.onPaste,
      "drop": _vm.onDrop,
      "focus": _vm.onFocus,
      "blur": function blur($event) {
        return _vm.$emit('blur', $event);
      }
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];