"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-aboutbuffer"
  }, [_c('h3', [_vm._v(_vm._s(_vm.b.name))]), _c('div', {
    staticClass: "kiwi-aboutbuffer-section",
    class: {
      'kiwi-aboutbuffer-section--closed': _vm.closedSections.about
    }
  }, [_c('h4', {
    on: {
      "click": function click($event) {
        return _vm.toggleSection('about');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  }), _vm._v(" " + _vm._s(_vm.$t('about')) + " ")]), _c('div', [_vm.b.created_at ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('created_on', {
    when: new Intl.DateTimeFormat().format(_vm.b.created_at)
  })) + " ")]) : _vm._e(), _vm.b.topic ? [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.formattedTopic)
    }
  }), _vm.b.topic_by && _vm.b.topic_when ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('topic_setby', {
    who: _vm.b.topic_by,
    when: new Intl.DateTimeFormat().format(_vm.b.topic_when)
  })) + " ")]) : _vm._e()] : _c('p', [_vm._v(_vm._s(_vm.$t('no_topic_set')))]), _c('p', {
    staticClass: "kiwi-aboutbuffer-usercount"
  }, [_c('a', {
    staticClass: "u-link",
    on: {
      "click": function click($event) {
        return _vm.sidebarState.showNicklist();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('person', {
    count: (0, _keys.default)(_vm.b.users || {}).length
  })) + " ")])])], 2)]), _c('div', {
    staticClass: "kiwi-aboutbuffer-section",
    class: {
      'kiwi-aboutbuffer-section--closed': _vm.closedSections.highlights
    }
  }, [_c('h4', {
    on: {
      "click": function click($event) {
        return _vm.toggleSection('highlights');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  }), _vm._v(" " + _vm._s(_vm.$t('highlights')) + " ")]), _c('div', [_vm.highlights.length > 0 ? _c('ul', _vm._l(_vm.highlights, function (msg) {
    return _c('li', {
      key: msg.id,
      staticClass: "kiwi-aboutbuffer-highlight",
      on: {
        "click": function click($event) {
          return _vm.buffer.scrollToMessage(msg.id);
        }
      }
    }, [_vm._v(" " + _vm._s(msg.nick ? msg.nick + ': ' : '')), _c('span', {
      domProps: {
        "innerHTML": _vm._s(msg.html)
      }
    })]);
  }), 0) : _c('p', [_vm._v(_vm._s(_vm.$t('nobody_mentioned_you')))])])]), _vm.areWeAnOp ? _c('div', {
    staticClass: "kiwi-aboutbuffer-section",
    class: {
      'kiwi-aboutbuffer-section--closed': _vm.closedSections.invite
    }
  }, [_c('h4', {
    on: {
      "click": function click($event) {
        return _vm.toggleSection('invite');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  }), _vm._v(" " + _vm._s(_vm.$t('invite_user')) + " ")]), _c('div', [_c('div', {
    staticClass: "kiwi-aboutbuffer-invite u-form"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inviteNick,
      expression: "inviteNick"
    }],
    staticClass: "u-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.inviteNick
    },
    on: {
      "keydown": _vm.inviteKeyDown,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.inviteNick = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "u-button u-button-secondary",
    on: {
      "click": _vm.inviteUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invite_user')) + " ")])]), _vm.invitableUsers.length > 0 ? _c('auto-complete', {
    ref: "autocomplete",
    staticClass: "kiwi-aboutbuffer-invite-auto-complete",
    attrs: {
      "items-per-page": "5",
      "items": _vm.invitableUsers,
      "filter": _vm.inviteNick
    },
    on: {
      "selected": _vm.inviteSelected
    }
  }) : _vm._e()], 1)]) : _vm._e(), _vm._l(_vm.pluginUiSections, function (plugin) {
    return _c('div', {
      key: plugin.id,
      staticClass: "kiwi-aboutbuffer-section",
      class: {
        'kiwi-aboutbuffer-section--closed': _vm.closedSections[plugin.id]
      }
    }, [_c('h4', {
      on: {
        "click": function click($event) {
          return _vm.toggleSection(plugin.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-angle-right"
    }), _vm._v(" " + _vm._s(plugin.title()) + " ")]), _c(plugin.component, _vm._b({
      tag: "component",
      attrs: {
        "plugin-props": {
          buffer: _vm.buffer,
          aboutbuffer: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false))], 1);
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];