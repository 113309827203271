"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-channellist",
    class: {
      'kiwi-channellist-padding-top': !_vm.list.length
    }
  }, [_c('div', {
    staticClass: "kiwi-channellist-content-container"
  }, [_c('div', {
    staticClass: "kiwi-channellist-nav"
  }, [_c('form', {
    staticClass: "u-form u-form--big kiwi-channellist-search",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "u-input",
    attrs: {
      "placeholder": _vm.$t('do_search')
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "u-button kiwi-channellist-refresh",
    class: {
      'u-button-primary': !_vm.isLoading,
      'u-button-secondary': _vm.isLoading
    },
    on: {
      "click": _vm.maybeUpdateList
    }
  }, [!_vm.isLoading ? _c('i', {
    staticClass: "fa fa-refresh",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-refresh fa-spin",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _vm.list.length ? _c('div', {
    staticClass: "kiwi-channellist-pagination"
  }, [_c('a', {
    on: {
      "click": _vm.prevPage
    }
  }, [_c('i', {
    staticClass: "fa fa-step-backward",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _vm._v(" " + _vm._s(_vm.page + 1) + " / " + _vm._s(_vm.maxPages + 1) + " "), _c('a', {
    on: {
      "click": _vm.nextPage
    }
  }, [_c('i', {
    staticClass: "fa fa-step-forward",
    attrs: {
      "aria-hidden": "true"
    }
  })])]) : _vm._e()]), !_vm.isLoading && !_vm.noResults ? _c('div', {
    staticClass: "kiwi-channellist-table"
  }, _vm._l(_vm.paginated, function (channel) {
    return _c('div', {
      key: channel.channel,
      staticClass: "kiwi-channellist-grid"
    }, [_c('div', {
      staticClass: "kiwi-channellist-users"
    }, [_vm._v(_vm._s(channel.num_users || 0))]), _c('div', {
      staticClass: "u-link kiwi-channellist-name",
      on: {
        "click": function click($event) {
          return _vm.joinChannel(channel.channel);
        }
      }
    }, [_vm._v(_vm._s(channel.channel))]), _c('span', {
      staticClass: "kiwi-channellist-topic",
      domProps: {
        "innerHTML": _vm._s(_vm.formatAndTrimTopic(channel.topic))
      }
    }), _c('div', {
      staticClass: "kiwi-channellist-join"
    }, [_c('a', {
      staticClass: "u-button u-button-primary",
      on: {
        "click": function click($event) {
          return _vm.joinChannel(channel.channel);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('container_join')))])])]);
  }), 0) : _vm.noResults ? _c('div', {
    staticClass: "kiwi-channellist-info"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('channel_list_nonefound')))])]) : _c('div', {
    staticClass: "kiwi-channellist-info"
  }, [_vm._v(_vm._s(_vm.$t('channel_list_fetch')))]), _c('div', {
    staticClass: "kiwi-channellist-nav"
  }, [_vm.list.length ? _c('div', {
    staticClass: "kiwi-channellist-pagination"
  }, [_c('a', {
    on: {
      "click": _vm.prevPage
    }
  }, [_c('i', {
    staticClass: "fa fa-step-backward",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _vm._v(" " + _vm._s(_vm.page + 1) + " / " + _vm._s(_vm.maxPages + 1) + " "), _c('a', {
    on: {
      "click": _vm.nextPage
    }
  }, [_c('i', {
    staticClass: "fa fa-step-forward",
    attrs: {
      "aria-hidden": "true"
    }
  })])]) : _vm._e()])])]);
};
var staticRenderFns = exports.staticRenderFns = [];