"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _trimInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/trim");
var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");
var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");
var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = parseMessage;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _includes = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/includes"));
var _startsWith = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/starts-with"));
var _lodash = require("lodash");
var EmojiProvider = _interopRequireWildcard(require("@/libs/EmojiProvider"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var _MessageFormatter = _interopRequireWildcard(require("@/libs/MessageFormatter"));
var _TextFormatting = require("@/helpers/TextFormatting");
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = _Object$keys(e); if (_Object$getOwnPropertySymbols) { var o = _Object$getOwnPropertySymbols(e); r && (o = _filterInstanceProperty(o).call(o, function (r) { return _Object$getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : _Object$getOwnPropertyDescriptors ? Object.defineProperties(e, _Object$getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, _Object$getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Receives a message, parses its irc blocks, and then finds urls, users, channels and emoji. Each
 * content is extracted to a separate block.
 * E.g. the message:
 *   "this is a message www.google.com and #kiwiirc"
 * will be split into the blocks:
 *   ["this is a message "]["www.google.com"][" and "]["#kiwiirc"]
 * The special content blocks will also contain additional info about their content according to
 * their type, such as the url, nick colour, emoji code...
 * @param {Array} blocks Array of style blocks from MessageFormatter
 * @param {Array} userList List of users to find within the message
 * @returns An array of blocks, where each special content will be extracted into a separate block.
 */
function parseMessage(message) {
  var formatOpts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var userList = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var blocks = (0, _MessageFormatter.default)(message, formatOpts);
  var formatedBlocks = blocks.reduce(function (acc, block, i) {
    return (0, _concat.default)(acc).call(acc, processBlock(block, userList));
  }, []);
  return formatedBlocks;
}

/**
 * Receives a block, splits it into words and tries finding channels, urls, nicks, and emoji.
 * @param {Object} block A block that came from MessageFormatter.formatIrcMessage()
 * @param {Object} userList List of users to find within the message
 * @returns An array of blocks, where each special content will be extracted into a separate block.
 */
function processBlock(block, userList) {
  var wordsRegex = /\S+/g;
  var wordMatch;
  var word;
  var specialMatches = [];
  // Array containing the special matches. Each `specialMatch` is an object with:
  // {
  //    index: <index of the match>
  //    match: <match, i.e. the text that will be extracted into a new block>
  //    block: <the block that will replace the match>
  // }

  // eslint-disable-next-line no-cond-assign
  var _loop = function _loop() {
    // `wordMatch` is an array with the match and the index of the match. We need that so
    // we can re-construct the original message.
    var matchInfo = wordMatch;
    word = wordMatch[0];
    var matches = matchChannel(word) || matchUrl(word) || matchUser(word, userList) || EmojiProvider.matchEmoji(word);
    if (!matches || !matches.length) {
      return "continue";
    }
    matches.forEach(function (match) {
      specialMatches.push({
        index: matchInfo.index + match.index,
        match: match.match,
        block: (0, _MessageFormatter.createNewBlock)(match.match, block.styles, match.type, match.meta)
      });
    });
  };
  while ((wordMatch = wordsRegex.exec(block.content)) !== null) {
    var _ret = _loop();
    if (_ret === "continue") continue;
  }

  // if there are no special matches, return the original block as is.
  if (specialMatches.length === 0) {
    return [block];
  }

  // split block with special matches.
  return mergeMatches(block, specialMatches);
}

/**
 * Finds a channel in the word match.
 * @param {String} word Word to be searched for channels.
 * @returns {object} Object with the matched channel, index within the word, and block.
 */
function matchChannel(word) {
  var channelMatch = _TextFormatting.channelRegex.exec(word);
  // matches the groups (spaces before)(prefix)(channel)(suffix punctuation)

  if (channelMatch === null) {
    return false;
  }
  var channel = channelMatch[3];

  // When the end of channel name is a closing bracket check for matching opening bracket
  // if no matching opening bracket is found consider the trailing bracket as punctuation
  if (Misc.hasUnmatchedTrailingBracket(channel)) {
    channel = channel.substring(0, channel.length - 1);
  }
  return [{
    index: channelMatch[1].length + channelMatch[2].length,
    match: channel,
    type: 'channel',
    meta: {
      channel: channel
    }
  }];
}

/**
 * Finds an url in the word match.
 * @param {String} word Word to be searched for urls.
 * @returns {Object} Object with the index of the url match in the block content, the
 * url match itself, and the new url text to be placed where the first url was.
 */
function matchUrl(word) {
  // this check avoids running the urlRegex, which would return the same result
  // but take much more time to process
  if (!((0, _includes.default)(word).call(word, '://') || (0, _startsWith.default)(word).call(word, 'www'))) {
    return false;
  }
  var urlMatch = _TextFormatting.urlRegex.exec(word);
  if (urlMatch === null) {
    return false;
  }
  var url = urlMatch[0];

  // Don't allow javascript execution
  if (url.match(/^javascript:/i)) {
    return false;
  }

  // Trim common punctuation from the end of a link. End of scentences etc.
  var punctuation = '.,;:';
  while (punctuation.indexOf(url[url.length - 1]) > -1) {
    url = url.substring(0, url.length - 1);
  }

  // Links almost always contain a matching opening bracket if the last character is a closing
  // bracket and should be part of the URL.
  // If there isn't a matching opening bracket but the URL ends in a closing bracket,
  // consider the closing bracket as punctuation outside of the URL.
  if (Misc.hasUnmatchedTrailingBracket(url)) {
    url = url.substring(0, url.length - 1);
  }

  // Add the http if no protocol was found
  var urlText = url;
  if (urlText.match(/^www\./i)) {
    urlText = 'http://' + url;
  }
  return [{
    index: urlMatch.index,
    match: url,
    matchText: urlText,
    type: 'url',
    meta: {
      url: urlText
    }
  }];
}

/**
 * Finds an user in the word match.
 * @param {String} word Word to be searched for users.
 * @returns {Object} Object with the index of the user match in the block content, the
 * user match itself, and the user colour.
 */
function matchUser(word, userList) {
  if (!userList) {
    return false;
  }
  var user = null;
  var punc = ',.!:;+)]?¿\\/<>@';
  var hasProp = Object.prototype.hasOwnProperty;
  var nickIdx = 0;
  var trimWord = (0, _trimInstanceProperty(_lodash))(word, punc);
  var normWord = trimWord.toUpperCase();
  if (hasProp.call(userList, normWord)) {
    user = userList[normWord];
    nickIdx = word.indexOf(trimWord);
  } else {
    return false;
  }
  return [{
    index: nickIdx,
    match: trimWord,
    type: 'user',
    meta: {
      user: trimWord,
      colour: user.getColour()
    }
  }];
}

/**
 * Merges the special matches into the block.
 * @param {Object} block A block that came from MessageFormatter.formatIrcMessage()
 * @param {Array} specialMatches Array of special matches. See definition above
 * @returns An array of blocks, where each special content will be extracted into a separate block.
 */
function mergeMatches(block, specialMatches) {
  var resultBlocks = [];
  var lastProcessedIndex = 0;

  // for each special content, creat a block with the text up to the match, and a block with the
  // special content itself.
  specialMatches.forEach(function (specialMatch, index) {
    var beforeMatchContent = block.content.substr(lastProcessedIndex, specialMatch.index - lastProcessedIndex);
    if (beforeMatchContent) {
      resultBlocks.push({
        content: beforeMatchContent,
        styles: _objectSpread({}, block.styles),
        containsContent: !!beforeMatchContent
      });
    }
    resultBlocks.push(specialMatch.block);
    lastProcessedIndex = specialMatch.index + specialMatch.match.length;
  });

  // remaining content after the last special content.
  if (lastProcessedIndex < block.content.length) {
    resultBlocks.push({
      content: block.content.substr(lastProcessedIndex),
      styles: _objectSpread({}, block.styles),
      containsContent: !!block.content.substr(lastProcessedIndex)
    });
  }
  return resultBlocks;
}
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["libs"]) window._kiwi_exports["libs"] = {};
window._kiwi_exports["libs"]["MessageParser"]
window._kiwi_exports.libs.MessageParser = exports.default ? exports.default : exports;
