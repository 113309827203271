"use strict";

var _Object$keys = require("@babel/runtime-corejs3/core-js-stable/object/keys");
var _Object$getOwnPropertySymbols = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-symbols");
var _filterInstanceProperty = require("@babel/runtime-corejs3/core-js-stable/instance/filter");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _Object$getOwnPropertyDescriptors = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptors");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));
function ownKeys(e, r) { var t = _Object$keys(e); if (_Object$getOwnPropertySymbols) { var o = _Object$getOwnPropertySymbols(e); r && (o = _filterInstanceProperty(o).call(o, function (r) { return _Object$getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : _Object$getOwnPropertyDescriptors ? Object.defineProperties(e, _Object$getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, _Object$getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('svg', {
    staticClass: "kiwi-avatar",
    attrs: {
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', _vm._b({
    attrs: {
      "clip-path": "url(#kiwi-avatar-clip)"
    }
  }, 'g', _setup.awayStatus.vbind, false), [_setup.avatar.showBackground ? _c('rect', {
    staticClass: "kiwi-avatar-background",
    style: _setup.avatar.backgroundStyle,
    attrs: {
      "width": "100",
      "height": "100"
    }
  }) : _vm._e(), _setup.avatar.hasImage ? _c('image', _vm._b({
    staticClass: "kiwi-avatar-image",
    attrs: {
      "width": "100",
      "height": "100",
      "preserveAspectRatio": "xMidYMid slice",
      "loading": "lazy"
    },
    on: {
      "error": function error($event) {
        _setup.avatar[_setup.avatar.sizeKey[_vm.size]].setFailed();
      }
    }
  }, 'image', _objectSpread({}, _setup.avatar[_setup.avatar.sizeKey[_vm.size]].vbind), false)) : _c('text', {
    staticClass: "kiwi-avatar-initials",
    attrs: {
      "font-size": _setup.avatar.initials.length === 1 ? '64px' : '44px',
      "x": "50",
      "y": "50",
      "dy": "0.36em",
      "text-anchor": "middle"
    }
  }, [_vm._v(_vm._s(_setup.avatar.initials))])]), _setup.awayStatus.show ? _c('circle', {
    staticClass: "kiwi-avatar-status",
    class: {
      'kiwi-avatar-status--toggle': _vm.allowToggle,
      'kiwi-avatar-status--away': _vm.user.isAway(),
      'kiwi-avatar-status--offline': _vm.user.isOffline()
    },
    attrs: {
      "transform": _setup.awayStatus.transform,
      "r": "12",
      "cx": "50",
      "cy": "0"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _setup.toggleAway.apply(null, arguments);
      }
    }
  }, [_vm.allowToggle ? _c('title', [_vm._v(_vm._s(_vm.$t('toggle_away')))]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];