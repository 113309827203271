"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Colours = _interopRequireWildcard(require("@/helpers/Colours"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  props: ['ircinput'],
  data: function data() {
    return {};
  },
  methods: {
    onColourClick: function onColourClick(event) {
      var colour = window.getComputedStyle(event.target, null).getPropertyValue('background-color');
      // Convert rgb(x,x,x) to its hex form
      var m = colour.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (m) {
        var rgbObj = {
          r: parseInt(m[1], 10),
          g: parseInt(m[2], 10),
          b: parseInt(m[3], 10)
        };
        colour = Colours.rgb2hex(rgbObj);
      }
      var code = event.target.dataset.code;
      this.ircinput.setColour(code, colour);
    },
    onResetClick: function onResetClick() {
      this.ircinput.resetStyles();
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["TextStyle"]
window._kiwi_exports.components.inputtools.TextStyle = exports.default ? exports.default : exports;
