"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-messageinfo",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.message.mentioned_urls.length > 0 ? _c('div', {
    staticClass: "kiwi-messageinfo-urls"
  }, _vm._l(_vm.message.mentioned_urls, function (url) {
    return _c('div', {
      key: url,
      staticClass: "kiwi-messageinfo-url"
    }, [_vm.showLinkPreviews ? _c('a', {
      staticClass: "u-button u-button-secondary",
      on: {
        "click": function click($event) {
          return _vm.urlPreview(url);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('preview')))]) : _vm._e(), _c('a', {
      staticClass: "u-link",
      attrs: {
        "href": url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(url))])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "kiwi-messageinfo-actions",
    class: {
      'kiwi-messageinfo-actions--open': _vm.requestingInput
    }
  }, [!_vm.requestingInput && _vm.message.nick && _vm.buffer.name !== _vm.message.nick && !_vm.isSelf() ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-button kiwi-messageinfo-reply",
    on: {
      "click": _vm.openQuery
    }
  }, [_vm._v(_vm._s(_vm.$t('reply_in_private')))]) : _vm._e(), !_vm.requestingInput ? _vm._l(_vm.pluginUiSections, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      staticClass: "u-link kiwi-messageinfo-button kiwi-messageinfo-plugin",
      attrs: {
        "plugin-props": {
          buffer: _vm.buffer,
          message: _vm.message
        },
        "buffer": _vm.buffer,
        "message": _vm.message
      }
    }, 'component', plugin.props, false));
  }) : _vm._e(), _vm.message.user && _vm.areWeAnOp() && !_vm.isSelf() ? [!_vm.requestingInput ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-button kiwi-messageinfo-ban-user",
    on: {
      "click": _vm.onBan
    }
  }, [_vm._v(_vm._s(_vm.$t('ban')))]) : _vm._e(), _c('input-prompt', {
    attrs: {
      "label": _vm.$t('kick_reason') + ':'
    },
    on: {
      "submit": _vm.onKick,
      "cancel": function cancel($event) {
        _vm.requestingInput = false;
      }
    }
  }, [!_vm.requestingInput ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-kick-user",
    on: {
      "click": function click($event) {
        _vm.requestingInput = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('kick')))]) : _vm._e()])] : _vm._e()], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];