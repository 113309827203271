"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _keys = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/keys"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-header kiwi-theme-bg",
    on: {
      "click": _vm.onHeaderClick
    }
  }, [_vm.isChannel() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_vm._v(_vm._s(_vm.buffer.name))]), _vm._v(_vm._s(_vm.buffer.name) + " ")])]), _c('div', {
    staticClass: "kiwi-header-center"
  }, [_vm.shouldShowTopic ? _c('div', {
    staticClass: "kiwi-header-topic",
    domProps: {
      "innerHTML": _vm._s(_vm.formattedTopic)
    }
  }) : _vm._e()]), _vm.isJoined && _vm.isConnected ? _c('div', {
    key: _vm.buffer.id,
    staticClass: "kiwi-header-options"
  }, [_c('div', {
    staticClass: "kiwi-header-option kiwi-header-option-settings",
    class: {
      'kiwi-header-option--active': _vm.sidebarSection === 'settings'
    }
  }, [_c('a', {
    attrs: {
      "title": _vm.$t('channel_settings')
    },
    on: {
      "click": function click($event) {
        return _vm.sidebarState.toggleBufferSettings();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-cog",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('div', {
    staticClass: "kiwi-header-option kiwi-header-option-nicklist",
    class: {
      'kiwi-header-option--active': _vm.sidebarSection === 'nicklist' || _vm.sidebarSection === 'user'
    }
  }, [_c('a', {
    attrs: {
      "title": _vm.$t('person', {
        count: (0, _keys.default)(_vm.buffer.users).length
      })
    },
    on: {
      "click": function click($event) {
        return _vm.sidebarState.toggleNicklist();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-users",
    attrs: {
      "aria-hidden": "true"
    }
  }), _c('span', [_vm._v(_vm._s((0, _keys.default)(_vm.buffer.users).length))])])]), _c('div', {
    staticClass: "kiwi-header-option kiwi-header-option-about",
    class: {
      'kiwi-header-option--active': _vm.sidebarSection === 'about'
    }
  }, [_c('a', {
    attrs: {
      "title": _vm.$t('more_information')
    },
    on: {
      "click": function click($event) {
        return _vm.sidebarState.toggleAbout();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _vm._l(_vm.pluginUiChannelElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      staticClass: "kiwi-header-option",
      attrs: {
        "plugin-props": {
          buffer: _vm.buffer,
          containerheader: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false));
  })], 2) : _vm._e(), !_vm.isJoined && _vm.isConnected ? _c('div', {
    staticClass: "kiwi-header-notjoined"
  }, [_c('a', {
    staticClass: "u-link kiwi-header-join-channel-button",
    on: {
      "click": _vm.joinCurrentBuffer
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('container_join')) + " ")])]) : _vm._e()] : _vm.isServer() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_vm._v(_vm._s(_vm.buffer.getNetwork().name))]), _vm._v(" " + _vm._s(_vm.buffer.getNetwork().name) + " ")])]), _c('div', {
    staticClass: "kiwi-header-center"
  }), _c('div', {
    staticClass: "kiwi-header-server-connection"
  }, [_vm.buffer.getNetwork().state === 'disconnected' ? _c('a', {
    staticClass: "u-button u-button-primary",
    on: {
      "click": _vm.onConnectButtonClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('connect')) + " ")]) : _vm.buffer.getNetwork().state === 'connecting' ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('connecting')) + " ")]) : _vm._e()])] : _vm.isQuery() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_c('away-status-indicator', {
    staticClass: "kiwi-header-awaystatus",
    attrs: {
      "network": _vm.buffer.getNetwork(),
      "user": _vm.network.userByName(_vm.buffer.name)
    }
  }), _vm._v(_vm._s(_vm.buffer.name) + " ")], 1), _c('away-status-indicator', {
    staticClass: "kiwi-header-awaystatus",
    attrs: {
      "network": _vm.buffer.getNetwork(),
      "user": _vm.network.userByName(_vm.buffer.name)
    }
  }), _vm._v(_vm._s(_vm.buffer.name) + " ")], 1)]), _c('div', {
    staticClass: "kiwi-header-center"
  }), _c('div', {
    key: _vm.buffer.id,
    staticClass: "kiwi-header-options"
  }, [_vm.userOnline ? _c('div', {
    staticClass: "kiwi-header-option kiwi-header-option-user",
    class: {
      'kiwi-header-option--active': _vm.sidebarSection === 'user'
    }
  }, [_c('a', {
    on: {
      "click": function click($event) {
        return _vm.toggleUser();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-user",
    attrs: {
      "aria-hidden": "true"
    }
  })])]) : _vm._e(), _vm._l(_vm.pluginUiQueryElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      staticClass: "kiwi-header-option",
      attrs: {
        "plugin-props": {
          buffer: _vm.buffer,
          containerheader: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer,
        "sidebar-state": _vm.sidebarState
      }
    }, 'component', plugin.props, false));
  })], 2)] : _vm.isSpecial() ? [_c('div', {
    staticClass: "kiwi-header-name-container"
  }, [_c('div', {
    staticClass: "kiwi-header-name"
  }, [_c('div', {
    staticClass: "kiwi-header-name-full"
  }, [_vm._v(_vm._s(_vm.buffer.name))]), _vm._v(" " + _vm._s(_vm.buffer.name) + " ")])]), _c('div', {
    staticClass: "kiwi-header-center"
  }), _c('div', {
    staticClass: "kiwi-header-options"
  })] : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];