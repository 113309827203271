"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _lodash = _interopRequireDefault(require("lodash"));
var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));
var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));
var _default = exports.default = {
  props: ['network'],
  data: function data() {
    return {
      sidebarOpen: false,
      page: 0,
      page_size: 200,
      search: '',
      last_updated: 0
    };
  },
  computed: {
    noResults: function noResults() {
      return this.listState === 'updated' && this.filteredList.length === 0;
    },
    isLoading: function isLoading() {
      return this.listState === 'updating';
    },
    listState: function listState() {
      return this.network.channel_list_state;
    },
    list: function list() {
      return this.network.channel_list || [];
    },
    filteredList: function filteredList() {
      var _this = this;
      var list = [];
      if (this.search.length <= 2) {
        list = this.list;
      } else {
        var _context;
        list = (0, _filter.default)(_context = this.list).call(_context, function (channel) {
          var found = false;
          if (channel.channel.toLowerCase().indexOf(_this.search) > -1) {
            found = true;
          }
          if (channel.topic.toLowerCase().indexOf(_this.search) > -1) {
            found = true;
          }
          return found;
        });
      }
      return _lodash.default.sortBy(list, 'num_users').reverse();
    },
    paginated: function paginated() {
      var offset = this.page * this.page_size;
      var list = this.filteredList;
      var channels = [];
      for (var i = offset; i < offset + this.page_size; i++) {
        if (list[i]) {
          channels.push(list[i]);
        }
      }
      return channels;
    },
    maxPages: function maxPages() {
      return Math.floor(this.filteredList.length / this.page_size);
    },
    canGoForward: function canGoForward() {
      return this.page * this.page_size >= this.filteredList.length;
    },
    canGoBackward: function canGoBackward() {
      return this.page > 0;
    }
  },
  watch: {
    search: function search() {
      this.page = 0;
    }
  },
  methods: {
    nextPage: function nextPage() {
      if (this.page < this.maxPages) {
        this.page++;
      }
    },
    prevPage: function prevPage() {
      if (this.page > 0) {
        this.page--;
      }
    },
    maybeUpdateList: function maybeUpdateList() {
      this.network.maybeUpdateChannelList();
    },
    formatAndTrimTopic: function formatAndTrimTopic(rawTopic) {
      var showModes = this.$state.setting('showChanlistModes');
      var topic = showModes ? rawTopic : rawTopic.replace(/^\[([^\]]+)\] ?/, '');
      var blocks = (0, _MessageParser.default)(topic, {
        extras: false
      });
      var content = (0, _Html.default)(blocks);
      return content;
    },
    joinChannel: function joinChannel(channelName) {
      var buffer = this.$state.getBufferByName(this.network.id, channelName);
      if (buffer) {
        // Switch buffer if its already exists
        this.$state.setActiveBuffer(this.network.id, channelName);
        return;
      }
      this.$state.addBuffer(this.network.id, channelName);
      this.network.ircClient.join(channelName);
      if (this.$state.ui.is_narrow) {
        // This is a mobile device
        // Switch to the channel so the user can see something happend
        this.$state.setActiveBuffer(this.network.id, channelName);
      }
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ChannelList"]
window._kiwi_exports.components.ChannelList = exports.default ? exports.default : exports;
