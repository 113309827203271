"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Captcha = _interopRequireDefault(require("@/components/Captcha"));
var _default = exports.default = {
  components: {
    Captcha: _Captcha.default
  },
  props: ['buffer', 'network'],
  data: function data() {
    return {
      forceLoader: false
    };
  },
  computed: {
    netStatus: function netStatus() {
      return this.buffer.getNetwork().state;
    },
    shouldShowLoading: function shouldShowLoading() {
      this.maybeForceLoader();
      if (this.network.state !== 'disconnected' || this.forceLoader) {
        return true;
      }
      return false;
    },
    restrictedServer: function restrictedServer() {
      return this.$state.setting('restricted');
    },
    readyToStart: function readyToStart() {
      return true;
    }
  },
  methods: {
    maybeForceLoader: function maybeForceLoader() {
      var _this = this;
      // The connection can fail almost imediately making it look like
      // the connection attempt didn't try anything. Make the connection
      // loder stay visible for at elast X seconds to indicate it's actually
      // tried something.
      var minimumLoaderViewtime = 2000;
      var networkState = this.network.state;
      if (networkState !== 'disconnected' && !this.forceLoader) {
        this.forceLoader = true;
        setTimeout(function () {
          _this.forceLoader = false;
        }, minimumLoaderViewtime);
      }
    },
    isChannel: function isChannel() {
      return this.buffer.isChannel();
    },
    isServer: function isServer() {
      return this.buffer.isServer();
    },
    isQuery: function isQuery() {
      return this.buffer.isQuery();
    },
    reconnect: function reconnect() {
      if (!this.readyToStart) {
        return;
      }
      if (this.buffer.isChannel()) {
        this.buffer.enabled = true;
      }
      this.buffer.getNetwork().ircClient.connect();
    },
    showNetworkSettings: function showNetworkSettings() {
      var network = this.buffer.getNetwork();
      network.showServerBuffer('settings');
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["NotConnected"]
window._kiwi_exports.components.NotConnected = exports.default ? exports.default : exports;
