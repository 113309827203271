"use strict";
'kiwi public';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Vue = require('vue');
var _default = exports.default = Vue.component('input-text', {
  props: ['value', 'label', 'type', 'showPlainText', 'disabled'],
  data: function data() {
    return {
      plainTextEnabled: false,
      inputIdCache: ''
    };
  },
  computed: {
    inputId: function inputId() {
      if (!this.inputIdCache) {
        // eslint-disable-next-line
        this.inputIdCache = 'inp_' + Math.floor(Math.random() * 1e17).toString(36);
      }
      return this.inputIdCache;
    },
    currentValue: {
      get: function getCurrentValue() {
        return this.value;
      },
      set: function setCurrentValue(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  methods: {
    updateValue: function updateValue(newValue) {
      this.$emit('input', newValue);
    },
    isEdgeBrowser: function isEdgeBrowser() {
      return navigator.appVersion.indexOf('Edge') > -1;
    }
  }
});
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["utils"]) window._kiwi_exports["components"]["utils"] = {};
window._kiwi_exports["components"]["utils"]["InputText"]
window._kiwi_exports.components.utils.InputText = exports.default ? exports.default : exports;
