"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.showCaptcha ? _c('div', {
    ref: "captchacontainer"
  }) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];