"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _slice = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/slice"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-buffersettings"
  }, [_c('div', {
    staticClass: "kiwi-buffersettings-alerts"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('settings_notify')))]), _c('hr'), _c('form', {
    staticClass: "u-form"
  }, [_c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingAlertOn,
      expression: "settingAlertOn"
    }],
    attrs: {
      "type": "radio",
      "name": "alert_on",
      "value": "message"
    },
    domProps: {
      "checked": _vm._q(_vm.settingAlertOn, "message")
    },
    on: {
      "change": function change($event) {
        _vm.settingAlertOn = "message";
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('settings_notify_all')))])]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingAlertOn,
      expression: "settingAlertOn"
    }],
    attrs: {
      "type": "radio",
      "name": "alert_on",
      "value": "highlight"
    },
    domProps: {
      "checked": _vm._q(_vm.settingAlertOn, "highlight")
    },
    on: {
      "change": function change($event) {
        _vm.settingAlertOn = "highlight";
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('settings_notify_mentioned')))])]), _c('label', {
    staticClass: "u-checkbox-wrapper"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingAlertOn,
      expression: "settingAlertOn"
    }],
    attrs: {
      "type": "radio",
      "name": "alert_on",
      "value": "never"
    },
    domProps: {
      "checked": _vm._q(_vm.settingAlertOn, "never")
    },
    on: {
      "change": function change($event) {
        _vm.settingAlertOn = "never";
      }
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('settings_notify_never')))])])]), _c('h3', [_vm._v(_vm._s(_vm.$t('settings')))]), _c('hr'), _c('form', {
    staticClass: "u-form"
  }, [_c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('settings_notify_mute')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingMuteSound,
      expression: "settingMuteSound"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingMuteSound) ? _vm._i(_vm.settingMuteSound, null) > -1 : _vm.settingMuteSound
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingMuteSound,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingMuteSound = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context;
            $$i > -1 && (_vm.settingMuteSound = (0, _concat.default)(_context = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingMuteSound = $$c;
        }
      }
    }
  })]), _c('label', [_c('span', [_vm._v(_vm._s(_vm.$t('settings_show_message_counts')))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.settingHideMessageCount,
      expression: "settingHideMessageCount"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.settingHideMessageCount) ? _vm._i(_vm.settingHideMessageCount, null) > -1 : _vm.settingHideMessageCount
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.settingHideMessageCount,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.settingHideMessageCount = (0, _concat.default)($$a).call($$a, [$$v]));
          } else {
            var _context2;
            $$i > -1 && (_vm.settingHideMessageCount = (0, _concat.default)(_context2 = (0, _slice.default)($$a).call($$a, 0, $$i)).call(_context2, (0, _slice.default)($$a).call($$a, $$i + 1)));
          }
        } else {
          _vm.settingHideMessageCount = $$c;
        }
      }
    }
  })])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];