"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));
var _default = exports.default = {
  props: ['buffer', 'network'],
  data: function data() {
    return {};
  },
  methods: {
    joinChannel: function joinChannel() {
      (0, _flags.default)(this.buffer).channel_badkey = false;
      this.buffer.join();
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["BufferKey"]
window._kiwi_exports.components.BufferKey = exports.default ? exports.default : exports;
