"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var _filter = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/filter"));
var _map = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/map"));
var _values = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/object/values"));
var _find = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/find"));
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));
var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));
var _AutoComplete = _interopRequireDefault(require("./AutoComplete"));
var _default = exports.default = {
  components: {
    AutoComplete: _AutoComplete.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      pluginUiSections: _GlobalApi.default.singleton().aboutBufferPlugins,
      closedSections: {},
      inviteNick: ''
    };
  },
  computed: {
    b: function b() {
      return this.buffer || {};
    },
    formattedTopic: function formattedTopic() {
      var blocks = (0, _MessageParser.default)(this.b.topic || '', {
        extras: false
      });
      var content = (0, _Html.default)(blocks);
      return content;
    },
    highlights: function highlights() {
      var _context, _context2, _context3, _context4, _context5, _context6;
      // Tap into buffer.message_count to force vuejs to update this function when
      // it changes
      /* eslint-disable no-unused-vars */
      var tmp = this.buffer.message_count;
      return (0, _sort.default)(_context = (0, _filter.default)(_context2 = (0, _filter.default)(_context3 = (0, _filter.default)(_context4 = (0, _filter.default)(_context5 = (0, _filter.default)(_context6 = this.buffer.getMessages()).call(_context6, function (m) {
        return m.isHighlight;
      })).call(_context5, function (m) {
        return m.type !== 'traffic';
      })).call(_context4, function (m) {
        return m.type !== 'topic';
      })).call(_context3, function (m) {
        return m.type !== 'mode';
      })).call(_context2, function (m) {
        return m.html;
      })).call(_context, function (a, b) {
        return b.time - a.time;
      });
    },
    areWeAnOp: function areWeAnOp() {
      return this.buffer.isUserAnOp(this.network.nick);
    },
    invitableUsers: function invitableUsers() {
      var _this = this,
        _context7,
        _context8;
      var wantedBuffer = function wantedBuffer(userBuffer) {
        return userBuffer.buffer.name === _this.buffer.name;
      };
      return (0, _map.default)(_context7 = (0, _filter.default)(_context8 = (0, _values.default)(this.network.users)).call(_context8, function (user) {
        var _context9;
        return !(0, _find.default)(_context9 = (0, _values.default)(user.buffers)).call(_context9, wantedBuffer);
      })).call(_context7, function (user) {
        return {
          text: user.nick
        };
      });
    }
  },
  methods: {
    toggleSection: function toggleSection(section) {
      this.$set(this.closedSections, section, !this.closedSections[section]);
    },
    inviteUser: function inviteUser() {
      if (!this.inviteNick) {
        return;
      }
      this.network.ircClient.invite(this.buffer.name, this.inviteNick);
      this.inviteNick = '';
    },
    inviteSelected: function inviteSelected(value, item) {
      this.inviteNick = value;
    },
    inviteKeyDown: function inviteKeyDown(event) {
      if (event.key === 'Tab') {
        this.$refs.autocomplete.selectCurrentItem();
        return;
      }
      this.$refs.autocomplete.handleOnKeyDown(event);
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["SidebarAboutBuffer"]
window._kiwi_exports.components.SidebarAboutBuffer = exports.default ? exports.default : exports;
