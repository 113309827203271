"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "u-input-confirm"
  }, [_vm.state === 'pre' ? _c('div', {
    on: {
      "click": _vm.prompt
    }
  }, [_vm._t("default")], 2) : _vm._e(), _vm.state === 'prompt' ? [_c('span', {
    staticClass: "u-input-confirm-label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('a', {
    staticClass: "u-button",
    class: ['u-button-' + _vm.connoteCss.yes],
    on: {
      "click": function click($event) {
        return _vm.complete(true);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('yes')) + " ")]), _c('a', {
    staticClass: "u-button",
    class: ['u-button-' + _vm.connoteCss.no],
    on: {
      "click": function click($event) {
        return _vm.complete(false);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('no')) + " ")])] : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];