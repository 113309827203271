"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "u-input-prompt",
    style: {
      display: _vm.block ? 'block' : 'inline-block'
    }
  }, [_vm.state === 'pre' ? _c('div', {
    on: {
      "click": _vm.prompt
    }
  }, [_vm._t("default")], 2) : _vm._e(), _vm.state === 'prompt' ? _c('form', {
    staticClass: "u-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.complete.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "u-input-prompt-label"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "u-input-prompt-inputs"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.value,
      expression: "value"
    }],
    staticClass: "u-input",
    domProps: {
      "value": _vm.value
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.cancel.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.value = $event.target.value;
      }
    }
  }), _c('a', {
    staticClass: "u-button u-button-primary",
    on: {
      "click": _vm.complete
    }
  }, [_c('span', {
    class: {
      'u-input-prompt-hidden': _vm.waiting
    }
  }, [_vm._v(_vm._s(_vm.$t('ok')))]), _vm.waiting ? _c('div', {
    staticClass: "u-input-prompt-waiting"
  }, [_c('i', {
    staticClass: "fa fa-spin fa-spinner",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()]), !_vm.hideCancel ? _c('a', {
    staticClass: "u-button u-button-warning",
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]) : _vm._e()])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];