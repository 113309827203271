"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _flags = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/flags"));
var _ContainerHeader = _interopRequireDefault(require("./ContainerHeader"));
var _Sidebar = _interopRequireDefault(require("./Sidebar"));
var _NotConnected = _interopRequireDefault(require("./NotConnected"));
var _MessageList = _interopRequireDefault(require("./MessageList"));
var _ServerView = _interopRequireDefault(require("./ServerView"));
var _default = exports.default = {
  components: {
    ContainerHeader: _ContainerHeader.default,
    Sidebar: _Sidebar.default,
    NotConnected: _NotConnected.default,
    MessageList: _MessageList.default,
    ServerView: _ServerView.default
  },
  props: ['network', 'buffer', 'sidebarState'],
  data: function data() {
    return {};
  },
  computed: {
    bufferType: function bufferType() {
      var type = '';
      if (!this.buffer) {
        type = 'none';
      } else if (this.buffer.isServer()) {
        type = 'server';
      } else if (this.buffer.isChannel()) {
        type = 'channel';
      } else if (this.buffer.isQuery()) {
        type = 'query';
      }
      return type;
    },
    unreadMessages: function unreadMessages() {
      var count = 0;
      var highlight = false;
      this.$state.networks.forEach(function (network) {
        network.buffers.forEach(function (buffer) {
          count += (0, _flags.default)(buffer).unread || 0;
          if ((0, _flags.default)(buffer).highlight) {
            highlight = true;
          }
        });
      });
      return {
        count: count,
        highlight: highlight
      };
    }
  },
  created: function created() {
    var _this = this;
    this.listen(this.$state, 'sidebar.toggle', function () {
      _this.$state.$emit('sidebar.' + (_this.sidebarState.isDrawn ? 'hide' : 'show'));
    });
    this.listen(this.$state, 'sidebar.show', function () {
      _this.sidebarState.showNicklist();
    });
    this.listen(this.$state, 'sidebar.hide', function () {
      _this.sidebarState.close();
    });
    this.listen(this.$state, 'userbox.show', function (user, opts) {
      _this.sidebarState.showUser(user);
    });
    this.listen(this.$state, 'userbox.hide', function () {
      _this.sidebarState.close();
    });
    this.listen(this.$state, 'document.keydown', function (ev) {
      // Return if not Page Up or Page Down keys
      if (ev.key !== 'PageUp' && ev.key !== 'PageDown') {
        return;
      }

      // if no messagelist, select the first tabbed content to allow channel list scrolling
      var messageList = _this.$el.querySelector('.kiwi-messagelist') || _this.$el.querySelector('.u-tabbed-content');
      if (!messageList) {
        return;
      }

      // Fix for chrome issue breaking layout when page up/down is pressed while inside
      // contenteditible or inputbox
      // https://bugs.chromium.org/p/chromium/issues/detail?id=890248
      ev.preventDefault();
      var scrollDistance = messageList.clientHeight - 0.1 * messageList.clientHeight;
      var scrollTop = messageList.scrollTop;
      var scrollMax = messageList.scrollHeight;
      if (ev.key === 'PageUp') {
        // up
        scrollTop -= scrollDistance;
        if (scrollTop < 0) {
          scrollTop = 0;
        }
      } else {
        // down
        scrollTop += scrollDistance;
        if (scrollTop > scrollMax) {
          scrollTop = scrollMax;
        }
      }
      messageList.scrollTop = scrollTop;
    });
  },
  methods: {
    toggleStateBrowser: function toggleStateBrowser() {
      this.$state.$emit('statebrowser.toggle');
    },
    toggleSidebar: function toggleSidebar() {
      if (this.buffer.isChannel()) {
        this.$state.$emit('sidebar.toggle');
      }
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Container"]
window._kiwi_exports.components.Container = exports.default ? exports.default : exports;
