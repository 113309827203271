"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    key: _vm.url,
    staticClass: "kiwi-mediaviewer-embedly"
  }, [_c('a', {
    ref: "embedlyLink",
    staticClass: "kiwi-embedly-card",
    attrs: {
      "href": _vm.url,
      "data-card-key": _vm.settings.key,
      "data-card-width": _vm.cardWidth,
      "data-card-chrome": "0",
      "data-card-controls": "0",
      "data-card-recommend": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t('media_loading', {
    url: _vm.url
  })))])]);
};
var staticRenderFns = exports.staticRenderFns = [];