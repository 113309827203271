"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _MessageList = _interopRequireDefault(require("./MessageList"));
var _NetworkSettings = _interopRequireDefault(require("./NetworkSettings"));
var _ChannelList = _interopRequireDefault(require("./ChannelList"));
var _default = exports.default = {
  components: {
    MessageList: _MessageList.default,
    NetworkSettings: _NetworkSettings.default,
    ChannelList: _ChannelList.default
  },
  props: ['network'],
  data: function data() {
    return {
      pluginUiElements: _GlobalApi.default.singleton().serverViewPlugins
    };
  },
  computed: {
    hasMessages: function hasMessages() {
      return this.network.serverBuffer().getMessages().length > 0;
    },
    serverBuffer: function serverBuffer() {
      return this.network.serverBuffer();
    },
    restrictedServer: function restrictedServer() {
      return this.$state.setting('restricted');
    },
    networkConnected: function networkConnected() {
      return this.network.state === 'connected';
    }
  },
  watch: {
    networkConnected: function networkConnected() {
      var _this = this;
      this.$nextTick(function () {
        // Vue won't update the tabs being displayed here so we to
        // manually update a property to force a re-render of the tabs
        _this.$refs.tabs.a++;
      });
    }
  },
  created: function created() {
    var _this2 = this;
    this.listen(this.$state, 'server.tab.show', function (tabName) {
      _this2.showTab(tabName);
    });
  },
  mounted: function mounted() {
    this.serverBuffer.active_tab = this.hasMessages ? 'messages' : 'settings';
  },
  methods: {
    showTab: function showTab(tabName) {
      this.$refs.tabs.setActiveByName(tabName);
    },
    tabChanged: function tabChanged(tabName) {
      var isMessages = tabName === 'messages';
      this.serverBuffer.active_tab = tabName;
      this.serverBuffer.isVisible = isMessages;
      this.serverBuffer.show_input = isMessages;
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["ServerView"]
window._kiwi_exports.components.ServerView = exports.default ? exports.default : exports;
