"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "u-tabbed-view"
  }, [_vm.showTabs ? _c('div', {
    key: _vm.prefixID + _vm.a,
    staticClass: "u-tabbed-view-tabs"
  }, _vm._l(_vm.tabs, function (c) {
    return _c('a', {
      key: c.name || c.header,
      class: {
        'u-tabbed-view-tab': true,
        'u-tabbed-view-tab--active': c.active
      },
      on: {
        "click": function click($event) {
          return _vm.setActive(c);
        }
      }
    }, [_vm._v(_vm._s(c.header))]);
  }), 0) : _vm._e(), _vm._t("default")], 2);
};
var staticRenderFns = exports.staticRenderFns = [];