"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-settings-aliases"
  }, [_c('form', {
    staticClass: "u-form"
  }, [_c('a', {
    staticClass: "u-link kiwi-settings-aliases-showhelp",
    on: {
      "click": function click($event) {
        _vm.show_help = !_vm.show_help;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('what_are_aliases')) + " ")]), _vm.show_help ? _c('div', {
    staticClass: "kiwi-settings-aliases-help"
  }, [_c('p', [_vm._v("Aliases let you rename existing IRC commands or even build entirely new ones.")]), _vm._m(0), _c('h4', [_vm._v("Variables")]), _vm._m(1), _vm._m(2), _c('h4', [_vm._v("Helper commands")]), _vm._m(3)]) : _vm._e(), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.aliasText,
      expression: "aliasText"
    }],
    staticClass: "kiwi-settings-aliases-input",
    domProps: {
      "value": _vm.aliasText
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.aliasText = $event.target.value;
      }
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" They must be one per line and in the form of "), _c('em', [_vm._v("/name /what it should do")]), _vm._v(". ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" There are several variables that may be used to refer to the current environment such as the active channel or active nick. "), _c('ul', [_c('li', [_c('em', [_vm._v("$server")]), _vm._v(" The current network name")]), _c('li', [_c('em', [_vm._v("$channel / $destination")]), _vm._v(" The current channel / buffer name")]), _c('li', [_c('em', [_vm._v("$nick")]), _vm._v(" The current nick")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" You can also use variables to read input from the typed command. "), _c('br'), _c('ul', [_c('li', [_c('em', [_vm._v("$0")]), _vm._v(" The command name")]), _c('li', [_c('em', [_vm._v("$1")]), _vm._v(" The first argument from the typed input")]), _c('li', [_c('em', [_vm._v("$2")]), _vm._v(" The second argument from the typed input")]), _c('li', [_c('em', [_vm._v("$1+")]), _vm._v(" From the first argument to the last argument")])]), _c('b', [_vm._v("Example 1:")]), _vm._v(" "), _c('em', [_vm._v("/greet /msg $1 Hello, $1!")]), _c('br'), _vm._v(" This creates an IRC command /greet that accepts one argument. Typing \"/greet username\" will execute \"/msg username Hello, username!\". "), _c('br'), _c('b', [_vm._v("Example 2:")]), _vm._v(" "), _c('em', [_vm._v("/ban /quote mode $channel +b $1+")]), _c('br'), _vm._v(" This creates an IRC command /ban that does a few things. Typing \"/ban nick1 nick2\" will execute \"/quote mode #activechannel +b nick1 nick2\". $channel is replaced with the active channel name, $1+ is replaced with all the typed input from the first word to the end. ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('ul', [_c('li', [_c('em', [_vm._v("/echo")]), _c('br'), _vm._v("Sends a message to the active buffer without sending it to the IRC network. Eg, /echo Something happened ")]), _c('li', [_c('em', [_vm._v("/lines")]), _c('br'), _vm._v(" Similar to Mirc script, this lets you execute multiples lines of commands separated by a pipe, \"|\"."), _c('br'), _vm._v(" Example: \"/lines /ban nick1 | /echo Banned user\" would first execute the /ban command, and then the /echo command. This comes in handy with creating short aliases such as the common \"/cycle\" command that parts and re-joins the active channel: \"/cycle /lines /part $channel | /join $channel\". ")])])]);
}];