"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime-corejs3/helpers/esm/defineProperty"));
var render = exports.render = function render(_c, _vm) {
  return _vm.props.user && _vm.$options.m.status(_vm.props) !== '' ? _c('span', {
    staticClass: "kiwi-typingstatusindicator kiwi-typing",
    class: (0, _defineProperty2.default)({
      'kiwi-typingstatusindicator--paused': _vm.$options.m.status(_vm.props) === 'paused'
    }, _vm.data.staticClass, true)
  }) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];