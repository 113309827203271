"use strict";
'kiwi public';

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _url = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/url"));
var _Logger = _interopRequireDefault(require("@/libs/Logger"));
var log = _Logger.default.namespace('Captcha');
var _default = exports.default = {
  props: ['network'],
  data: function data() {
    return {
      captchaUrl: '',
      captchaSiteId: '',
      captchaResponse: '',
      showCaptcha: false
    };
  },
  created: function created() {
    var _this = this;
    var options = this.$state.settings.startupOptions;
    this.captchaSiteId = options.captchaSiteId || options.recaptchaSiteId || '';
    try {
      this.captchaUrl = new _url.default(options.captchaUrl || options.recaptchaUrl || 'https://www.google.com/recaptcha/api.js');
      var params = this.captchaUrl.searchParams;
      params.set('onload', 'captchaLoaded');
      params.set('render', 'explicit');
    } catch (error) {
      log.error('failed to create captcha url', error);
    }
    this.listen(this.$state, 'network.connecting', function (event) {
      event.network.ircClient.once('socket connected', function () {
        if (_this.captchaResponse) {
          event.network.ircClient.raw('CAPTCHA', _this.captchaResponse);
        }
      });
    });
    this.listen(this.$state, 'irc.raw.CAPTCHA', function (command, event, network) {
      if (network !== _this.network) {
        return;
      }
      if (event.params[0] === 'NEEDED') {
        _this.loadCaptcha();
      }
    });
  },
  methods: {
    getCaptcha: function getCaptcha() {
      var _ref, _window$grecaptcha;
      return (_ref = (_window$grecaptcha = window.grecaptcha) !== null && _window$grecaptcha !== void 0 ? _window$grecaptcha : window.turnstile) !== null && _ref !== void 0 ? _ref : window.hcaptcha;
    },
    loadCaptcha: function loadCaptcha() {
      var _this2 = this;
      if (this.getCaptcha()) {
        this.captchaShow();
        return;
      }

      // captcha calls this callback once it's loaded and ready to be used
      window.captchaLoaded = function () {
        _this2.captchaShow();
      };
      var scr = document.createElement('script');
      scr.src = this.captchaUrl.toString();
      scr.defer = true;
      document.head.appendChild(scr);
    },
    captchaShow: function captchaShow() {
      var _this3 = this;
      this.showCaptcha = true;
      this.$nextTick(function () {
        _this3.getCaptcha().render(_this3.$refs.captchacontainer, {
          'sitekey': _this3.captchaSiteId,
          'callback': _this3.captchaSuccess,
          'expired-callback': _this3.captchaExpired
        });
      });
    },
    captchaSuccess: function captchaSuccess(response) {
      this.captchaResponse = response;

      // If we have a network instance already, send the captcha response
      if (this.network && this.network.state === 'connecting') {
        this.network.ircClient.raw('CAPTCHA', response);
      }
      this.showCaptcha = false;
    },
    captchaExpired: function captchaExpired() {
      this.captchaResponse = '';
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["Captcha"]
window._kiwi_exports.components.Captcha = exports.default ? exports.default : exports;
