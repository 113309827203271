"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
function _getRequireWildcardCache(e) { if ("function" != typeof _WeakMap) return null; var r = new _WeakMap(), t = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? _Object$getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  props: ['ircinput'],
  computed: {
    emojis: function emojis() {
      var list = {};
      var available = this.$state.setting('emojis');
      _lodash.default.each(available, function (code, ascii) {
        if (!code) {
          // Emoji has an empty value, skip
          return;
        }
        list[code] = ascii;
      });
      return list;
    },
    location: function location() {
      return this.$state.setting('emojiLocation');
    }
  },
  methods: {
    onImgClick: function onImgClick(event) {
      var url = window.getComputedStyle(event.target, null).getPropertyValue('background-image');
      url = Misc.extractURL(url);
      var code = event.target.dataset.code;
      this.ircinput.addImg(code, url);
    }
  }
};
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
if(!window._kiwi_exports["components"]["inputtools"]) window._kiwi_exports["components"]["inputtools"] = {};
window._kiwi_exports["components"]["inputtools"]["Emoji"]
window._kiwi_exports.components.inputtools.Emoji = exports.default ? exports.default : exports;
